<template>
  <div id="emailEditorPage">
    <!-- Email editor fields - from, to, subject, provider etc. -->
    <div class="emailMetadataHeader">
      <el-form :inline="true" :model="emailInputForm" :rules="emailInputFormRules" class="demo-form-inline" ref="emailInputForm">
        <div style="display: flex">
          <el-form-item label="Subject" prop="subject" style="flex: 1">
            <el-input :disabled="readOnly" id="inputEmailSubject" size="small" v-model="emailInputForm.subject" placeholder="Subject"> </el-input>
            <el-popover v-if="!readOnly" popper-class="emojiPopover" v-model="subjectEmojiPicker" trigger="click">
              <picker :showPreview="false" :perLine="20" style="width: 355px" :exclude="['flags']" @select="addEmojiToSubject" :native="true" set="emojione" />
              <el-button type="text" info class="emailEditoremojiIcon" slot="reference">
                <i class="fa fa-smile-o" aria-hidden="true"></i>
              </el-button>
            </el-popover>
            <el-popover v-if="!readOnly" popper-class="dynamicUserProp" v-model="subjectDynmicPopover" trigger="click">
              <DynamicUserProperty v-show="!readOnly" :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'subject'" @setDynamicPropText="addDynamicPropToSubject"></DynamicUserProperty>
              <el-button type="text" info class="emailEditorUserIconBtn" slot="reference">
                <i class="el-icon-user-solid" aria-hidden="true"></i>
              </el-button>
            </el-popover>
          </el-form-item>
          <el-form-item label="From Name" prop="fromName">
            <el-input :disabled="readOnly" size="small" v-model="emailInputForm.fromName" placeholder="From Name" style="width: 250px"> </el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="Email Preview Text (Optional)" prop="previewText" style="flex: 1">
            <el-input :disabled="readOnly" id="inputEmailPreviewText" size="small" v-model="emailInputForm.previewText" placeholder="Email Preview Text"> </el-input>
            <el-popover v-if="!readOnly" popper-class="emojiPopover" v-model="previewTextEmojiPicker" trigger="click">
              <picker :showPreview="false" :perLine="20" style="width: 355px" :exclude="['flags']" @select="addEmojiToPreviewText" :native="true" set="emojione" />
              <el-button type="text" info class="emailEditoremojiIcon" slot="reference">
                <i class="fa fa-smile-o" aria-hidden="true"></i>
              </el-button>
            </el-popover>
            <el-popover v-if="!readOnly" popper-class="dynamicUserProp" v-model="previewTextDynmicPopover" trigger="click">
              <DynamicUserProperty v-show="!readOnly" :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'subject'" @setDynamicPropText="addDynamicPropToPreviewText"></DynamicUserProperty>
              <el-button type="text" info class="emailEditorUserIconBtn" slot="reference">
                <i class="el-icon-user-solid" aria-hidden="true"></i>
              </el-button>
            </el-popover>
          </el-form-item>
          <el-form-item label="Reply-To Email (Optional)" prop="replyToMail">
            <el-input style="width: 300px" :disabled="readOnly" size="small" v-model="emailInputForm.replyToMail" placeholder="Enter Reply To Email Id"> </el-input>
          </el-form-item>
          <el-form-item label="Email Provider" prop="providerId">
            <el-select :disabled="readOnly" v-model="emailInputForm.providerId" placeholder="Select Email Channel" size="small" style="width: 250px">
              <el-option v-for="item in providerList" :key="item.id" :label="item.provider_name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <!-- Editor -->
    <div class="editorPopup">
      <!-- Editors for different type -->
      <div class="editorContainer" :class="{ fullScreen: showEmailEditor }">
        <!-- Editor Header -->
        <el-row class="mailHeader" v-if="!readOnly">
          <el-col :span="7">
            <template v-if="showEmailEditor">
              <div class="editorButtons" v-bind:class="{ selectedEditorButton: !previewMode }" @click="onTogglePreview(false)">Editor</div>
              <div class="editorButtons" v-bind:class="{ selectedEditorButton: previewMode }" @click="onTogglePreview(true)">Preview</div>
            </template>
            &nbsp;
          </el-col>

          <el-col :span="17" class="mailEditorRightSide">
            <!-- Editor Type -->
            <template v-if="!readOnly && showEmailEditor">
              <el-select class="mr-2" v-model="editorType" size="mini" @change="onEditorTypeChange">
                <el-option value="dragAndDrop" label="Drag & Drop Editor"></el-option>
                <el-option value="unlayerDragDrop" label="Unlayer Editor"></el-option>
                <el-option value="richText" label="Text Editor"></el-option>
                <el-option value="customHtml" label="Custom HTML"></el-option>
              </el-select>
            </template>

            <!-- Attachments Button -->
            <!-- <el-badge :value="attachments.length" class="emailAttachBdg">
              <el-button @click="showAttachmentsDialog = true" type="plain" size="mini">Attachments</el-button>
            </el-badge> -->

            <!-- Save Template -->
            <template v-if="showEmailEditor">
              <el-button size="mini" type="info" plain @click="showSaveTemplateDialog()"> Save Template </el-button>
            </template>

            <!-- change template button -->
            <template v-if="showEmailEditor">
              <el-button size="mini" type="info" plain @click="onShowTemplateSelectorDialog()"> Change Template </el-button>
            </template>

            <!-- Send Test Email -->
            <el-button size="mini" type="primary" plain @click="showSendTestMailDialog()"> Send Test Mail </el-button>

            <template v-if="!isJourneyCampaign">
              <el-button size="mini" type="primary" @click="onSaveDraft(false)" v-if="showEmailEditor"> Save </el-button>
              <el-button size="mini" type="primary" @click="onSaveDraft(true)" v-if="showEmailEditor"> Save & Close </el-button>
            </template>

            <el-button size="mini" type="primary" v-if="!showEmailEditor" icon="el-icon-edit" @click="onToggleEditor(true)">&nbsp;&nbsp;Edit Email</el-button>

            <el-tooltip class="item" effect="dark" content="Exit Editor" placement="bottom" v-if="showEmailEditor">
              <el-button size="mini" type="danger" icon="el-icon-close" @click="onToggleEditor(false)" circle></el-button>
            </el-tooltip>
          </el-col>
        </el-row>

        <!-- rich text editor -->
        <div class="richTextContainer" v-if="!previewMode && editorType == 'richText'">
          <CkTextEditor editorType="dragDrop-RichText" class="ckEditor" ref="ckeditorRef"></CkTextEditor>
          <el-popover popper-class="emojiPopover" v-model="emojiPicker" trigger="click">
            <picker :showPreview="false" :perLine="20" style="width: 355px" :exclude="['flags']" @select="addEmojiToCkEditor" :native="true" set="emojione" />
            <el-button type="text" info class="emailEditoremojiIcon" slot="reference">
              <i class="fa fa-smile-o" aria-hidden="true"></i>
            </el-button>
          </el-popover>
          <el-popover v-model="ckEditorDynmicPopover" popper-class="dynamicUserProp" trigger="click">
            <DynamicUserProperty ref="dynamicUserPropertyComponent" :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'subject'" @setDynamicPropText="addDynamicPropToCkEditor"></DynamicUserProperty>
            <el-button type="text" info class="emailEditorUserIconBtn" slot="reference">
              <i class="el-icon-user-solid" aria-hidden="true"></i>
            </el-button>
          </el-popover>
          <el-tooltip class="imageUploader" content="Insert image" placement="bottom">
            <i class="fa fa-picture-o" aria-hidden="true" @click="showRichTextImageUploader"></i>
          </el-tooltip>
          <ImageManager @onSelect="onInsertRichTextImage" ref="richTextImageManager"></ImageManager>
        </div>

        <!-- custom html editor -->
        <div class="customHtmlContainer" v-if="!previewMode && editorType == 'customHtml'">
          <el-input type="textarea" :rows="15" placeholder="Paste HTML content here" resize="none" v-model="customHtml"> </el-input>
        </div>

        <!-- Drag & Drop Editor -->
        <DragDropEditor v-show="!previewMode && editorType == 'dragAndDrop'" ref="dragDropEditor" :isJourneyCampaign="isJourneyCampaign"></DragDropEditor>

        <!-- Unlayer Editor -->
        <div id="unlayerEditor" class="unlayerDragDrop" v-show="!previewMode && editorType == 'unlayerDragDrop'">
          <UnlayerEmailEditor ref="unlayerEmailEditor" />
        </div>

        <!-- Show Preview -->
        <div v-if="previewMode" class="previewContainer">
          <template v-if="(finalContent.json && finalContent.json.rows && finalContent.json.rows.length > 0) || finalContent.html">
            <div class="previewSwitch">
              <el-radio-group v-model="previewDeviceType">
                <el-radio-button label="desktop"> <i class="el-icon-monitor"></i> &nbsp; Desktop </el-radio-button>
                <el-radio-button label="mobile"> <i class="el-icon-mobile-phone"></i> &nbsp; Mobile </el-radio-button>
              </el-radio-group>
            </div>

            <iframe class="htmlPreview" :class="['preview-' + previewDeviceType]" :srcdoc="finalContent.html"></iframe>
          </template>
          <div v-else class="htmlPreview bg-white">
            <div class="w-full my-20 text-center">
              Email Content Not Mentioned Yet.
              <div class="mt-20"></div>
              <el-button type="primary" @click="onToggleEditor(true)">Start Writing Email</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- send test email dialog -->
    <el-dialog append-to-body class="sendTestEmailPopUp" :visible.sync="sendTestMailDialogVisible" width="700px" center title="Send Test Email" :before-close="clearTestEmailForm">
      <!-- Warning Notes -->
      <div class="testMailNotes">
        <div class="warningNote">Test mail will not include dynamic contents. Test mails are for design preview purpose only.</div>
      </div>
      <div class="mb-3 pt-2">
        <el-checkbox v-model="isCommaSeepratedList"> Use comma seperated email list</el-checkbox>
      </div>
      <el-form :model="testEmailForm" ref="testEmailForm" class="form-vertical-label" @submit.native.prevent>
        <el-form-item class="emailInput">
          <div class="el-form-item__label">Send Email To</div>
          <div v-if="!isCommaSeepratedList">
            <el-tag :key="email" v-for="email in emails" closable :disable-transitions="false" type="info" @close="removeEmails(email)">{{ email }}</el-tag>
            <el-input class="input-new-tag" size="small" v-model="testEmailForm.emailValue" ref="saveTagInput" type="email" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm()"></el-input>
          </div>
          <div v-else>
            <el-input type="textarea" :rows="4" placeholder="Enter emails, seperate multiple emails by comma" v-model="commaSeperatedEmails"> </el-input>
          </div>
        </el-form-item>
        <el-form-item class="buttonsContainer">
          <el-button type="primary" @click="onSendTestEmailSubmit('testEmailForm')" :loading="sendingTestEmail">Send Test Email</el-button>
          <el-button @click="closeSendTesEmailDialog()" type="danger" plain>Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Save Template dialog -->
    <el-dialog append-to-body id="templateFromPopUp" :visible.sync="saveTemplateDialogVisible" width="500px" center title="Save Email Template">
      <el-form ref="templateNameForm" class="form-vertical-label" @submit.native.prevent>
        <!-- // template name -->
        <el-form-item required label="Template Name">
          <el-input v-model="saveTemplateName" @keyup.enter.native="onSumbitTemplateName" placeholder="Enter template name to continue"> </el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="onSumbitTemplateName()" :loading="savingTemplate">Save </el-button>
          <el-button type="danger" @click="saveTemplateDialogVisible = false">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Attachments dialog  -->
    <el-dialog append-to-body class="fileUploadDailogBox" :visible.sync="showAttachmentsDialog" width="40%" center title="Email Attachments">
      <div class="hint">Please upload the file less than 15Mb</div>

      <!-- file Uploader -->
      <div class="fileUploader">
        <el-upload class="upload-demo" :before-upload="onFileAttachmentUpload" :on-remove="onAttachmentsToRemove" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Attach the file</div>
        </el-upload>

        <!-- attachment Detail -->
        <table class="attachmentDetail">
          <tr v-for="(attachedfile, index) in attachments" :key="attachedfile.value" :label="attachedfile.label" :value="attachedfile.value">
            <td>{{ attachedfile.name }}</td>
            <td class="fileSize">{{ getBytesDisplayText(attachedfile.size, true) }}</td>
            <td class="removeBtn">
              <el-button type="danger" @click="removeAttachedFile(index, attachedfile)" size="mini">Remove</el-button>
            </td>
          </tr>
        </table>
      </div>
    </el-dialog>

    <!-- Template Gallery dialog -->
    <TemplateGallery ref="templateGallery" @templateSelected="onApplyTemplate"></TemplateGallery>

    <!-- // iframe - at save as template => to generate screenshot of template image -->
    <iframe ref="dummyFrame" style="width: 0px; height: 0px; border: none; position: absolute"> </iframe>
  </div>
</template>

<style lang="scss" src="./emailEditor.scss"></style>
<style lang="scss" src="../../dragDropEditor/blockCss/commonDragDropStyles.scss"></style>

<script>
import EmailEditorComponent from './emailEditorComponent';
export default EmailEditorComponent;
</script>
